import { NotFoundError } from 'CommonExceptions';
import Alert from 'Components/Alert';
import { hide, toggleClass } from 'Components/domHelpers';
import { Cond, RefFormData, FormPassthru, FormText, FormStatic, wrapSubmitHandler } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import LoadingIndicator from 'Components/LoadingIndicator';
import Modal from 'Components/Modal';
import TbIcons from 'Components/TbIcons';
import { ToggleButton, TableToolbar, SearchInput } from './LcmComponents';
import { formatTimestamp } from 'DateTime';
import { RangeInputPopup } from 'Components/RangeInputPopup';
import { PagingToolbar } from './Paging';
import { SortableTable, Sorter } from './Tables';

import { BroadcastQueueList } from './BroadcastQueueList';
import { BroadcastTogglePauseButton, BroadcastDisconnectButton } from './BroadcastComponents';
import {
  GAIN_MIN,
  GAIN_MAX,

  ADD_MODAL_STATE_CLOSED,
  ADD_MODAL_STATE_CHOOSE_ACTION,

  ADD_MODAL_STATE_UPLOAD,
  ADD_MODAL_STATE_UPLOADING,

  ADD_MODAL_STATE_LOADING,
  ADD_MODAL_STATE_SELECT,

  ADD_MODAL_STATE_ADD_TO_PLAYLIST,
} from './BroadcastController';
import AudioUploadControl from './AudioUploadControl';

import { getRecordingInfo } from './ApiGetters';
import ModalAlert from './ModalAlert';
import ModalConfirm from './ModalConfirm';
import appErrorHandler from './appErrorHandler';

import s from './strings';

const TOOLBAR_ENABLED = false;

export class BroadcastPanel {
  static isClassComponent = true;

  constructor({ ctrl }) {
    const hooks = new Hooks();

    ctrl.on('update', () => hooks.run(ctrl));

    this._ctrl = ctrl;

    const modalDisconnectCall = new ModalConfirm({
      title: s.Broadcast.stop,
      message: s.Broadcast.stopConfirm,
      confirmLabel: s.Broadcast.stop,
      confirm: () => {
        this._broadcastCommand('disconnectCall');
      },
    });

    const clearQueueModal = new ModalConfirm({
      title: s.Broadcast.clearQueue,
      message: s.Broadcast.confirmClearQueue,
      confirmLabel: s.Broadcast.clearQueue,
      confirm: () => this._ctrl.removeAll(),
    });

    const togglePause = () => {
      if (!ctrl.activeBroadcast || ctrl.activeBroadcast.isPlaceholder)
        return;

      this._broadcastCommand('streamPauseToggle');
    };
    const endBroadcast = () => {
      if (!ctrl.activeBroadcast || ctrl.activeBroadcast.isPlaceholder)
        return;

      modalDisconnectCall.display();
    };

    const ExpandText = () => {
      return (
        <>
          <span use:hook={hooks.textContent('queueVisible', val => val ? s.Broadcast.collapse : s.Broadcast.expand)} />
          {' '}
          (<span use:hook={hooks.textContent('broadcastQueueCount')} />)
        </>
      );
    };

    const ctx = {
      hooks,
      ctrl,
    };

    let selectionCheckbox;

    this.root = (
      <div
        class="broadcast-panel in-main-conf"
        use:hook={hooks.show('panelVisible')}
      >
        <div class="managed-queue-control">
          <Cond test={TOOLBAR_ENABLED}>
            <div class="btn-toolbar-discrete managed-queue-table-controls" role="group">
              <ToggleButton className="btn btn-primary" ref={selectionCheckbox} onclick={() => this._ctrl.toggleSelectAll()} />
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.prop('selectedCount', 'disabled', val => !val)}
                onclick={() => this._ctrl.removeSelected()}
              >
                {s.handRaising.lower}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onclick={() => clearQueueModal.display()}
              >
                {s.handRaising.lowerAll}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.prop('selectedCount', 'disabled', val => !val)}
                onclick={() => this._ctrl.move('top')}
              >
                {s.handRaising.moveToTop}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.prop('selectedCount', 'disabled', val => val !== 1)}
                onclick={() => this._ctrl.move('up')}
              >
                {s.handRaising.moveUp}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.prop('selectedCount', 'disabled', val => val !== 1)}
                onclick={() => this._ctrl.move('down')}
              >
                {s.handRaising.moveDown}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.prop('selectedCount', 'disabled', val => !val)}
                onclick={() => this._ctrl.move('bottom')}
              >
                {s.handRaising.moveToBottom}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.prop('isExpandable', 'disabled', val => !val)}
                onclick={() => this._ctrl.toggleQueueVisible()}
              >
                <ExpandText />
              </button>
            </div>
          </Cond>
          <BroadcastActive
            ctrl={ctrl}
            onTogglePause={togglePause}
            onEndBroadcast={endBroadcast}
          />
          <div use:hook={hooks.show('queueVisible')}>
            <BroadcastQueueList
              ref={this._broadcastQueueList}
              ctrl={ctrl}
              onTogglePause={togglePause}
              onEndBroadcast={endBroadcast}
            />
          </div>

          <div
            class="expand-indicator"
            use:hook={hooks.show('isExpandable')}
          >
            <div class="expand-indicator-inner" onclick={() => this._ctrl.toggleQueueVisible()}>
              <ExpandText /><span class="tbicon" use:hook={hooks.textContent('queueVisible', val => val ? TbIcons.MENU_UP : TbIcons.MENU_DOWN)}></span>
            </div>
          </div>
        </div>
        <BroadcastAddModal ctx={ctx} />
      </div>
    );

    hooks.add('queueVisible', val => {
      toggleClass(this._broadcastQueueList.root, 'draggable', val);
    });

    if (TOOLBAR_ENABLED) {
      hooks.add('selectState', val => {
        selectionCheckbox.toggleAttribute('data-checked', val === 'all');
        selectionCheckbox.toggleAttribute('data-indeterminate', val === 'some');
      });
    }
  }

  _broadcastCommand(commandName) {
    this._ctrl[commandName]()
      .catch(err => {
        let errorCode;
        if (err instanceof NotFoundError) {
          errorCode = 'ERR_API_CALL_NOT_FOUND';
        }
        if (!errorCode) {
          errorCode = appErrorHandler(err);
        }

        ModalAlert.display(errorCode);
      });
  }
}

export class BroadcastActive {
  static isClassComponent = true;

  constructor({ ctrl, onTogglePause, onEndBroadcast }) {
    const hooks = new Hooks();
    const activeBroadcastHooks = new Hooks();

    ctrl.on('update', () => {
      hooks.run(ctrl);
      activeBroadcastHooks.run(ctrl.activeBroadcast);
    });

    this._ctrl = ctrl;

    this.root = (
      <ol class="managed-queue-list">
        <li class="broadcast-active">
          <span class="playing-label">{s.Broadcast.callLabel}</span>

          <span
            use:hook={activeBroadcastHooks.textContent('description', val => val || s.Broadcast.noActiveBroadcasts)}
          />

          <div
            class="ml-auto"
            use:hook={activeBroadcastHooks.show('broadcastID')}
          >
            <div use:hook={hooks.show('writable')}>
              <RangeInputPopup
                hooks={activeBroadcastHooks}
                valueProp="inGain"
                onChange={gain => ctrl.setInGain(gain)}
                min={GAIN_MIN}
                max={GAIN_MAX}
              >
                <button class="btn-flat">
                  {TbIcons.VOLUME_UP}
                </button>
              </RangeInputPopup>
            </div>
          </div>

          <span use:hook={activeBroadcastHooks.show('broadcastID')}>
            <span class="position-duration">
              <span use:hook={activeBroadcastHooks.textContent('position', position => formatTimestamp(position * 1000))} />
              {' / '}
              <span use:hook={activeBroadcastHooks.textContent('duration', duration => formatTimestamp(duration * 1000))} />
            </span>

            <span use:hook={hooks.show('writable')}>
              <BroadcastTogglePauseButton hooks={activeBroadcastHooks} onclick={onTogglePause} />
              <BroadcastDisconnectButton onclick={onEndBroadcast} />
            </span>
          </span>
        </li>
      </ol>
    );
  }
}

export class BroadcastEnqueueModal {
  constructor({ ctrl, onComplete }) {
    const onSubmit = () => {
      this.hide();

      const {
        cdrID,
        recID,
        duration,
        customID,
      } = this._form.getAllValues();

      ctrl.addToQueue({
        cdrID,
        recID,
        duration,
        customID,
      });

      onComplete();
    };

    this._modal = new Modal({
      appendToBody: true,
      title: s.Broadcast.enqueue,
      children: (
        <form onsubmit={wrapSubmitHandler(onSubmit)}>
          <div class="modal-body">
            <div class="form-horizontal">
              <Alert ref={this._alert} />
              <BroadcastEnqueueForm ctrl={ctrl} ref={this._form} />
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.Broadcast.enqueue}</button>
            <button type="button" class="btn btn-primary" onclick={() => this.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });

    this._loading = new LoadingIndicator(this._modal.bodyAndFooter, {
      label: s.lblLoading,
    });
  }

  display({ cdrID, recID, timezone }) {
    hide(this._alert);

    const params = {
      cdrID,
      recID,
      timezone,
    };

    Promise.resolve()
      .then(() => {
        this._loading.show();
        this._modal.show();
      })
      .then(() => getRecordingInfo(params))
      .then(res => res.items[0])
      .then(rec => {
        if (!rec) {
          this.hide();
          ModalAlert.display('ERR_API_RECORDING_NOT_FOUND');
          return;
        }

        this._form.render(rec);
      })
      .catch(err => {
        this.hide();
        ModalAlert.display(appErrorHandler(err));
      })
      .then(() => this._loading.hide());
  }

  hide() {
    this._modal.hide();
  }
}

class BroadcastEnqueueForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctrl, ref }) {
    super();

    if (ref) {
      ref(this);
    }

    this._ctrl = ctrl;

    this.root =
      <>
        <FormPassthru form={this} name="cdrID" />
        <FormPassthru form={this} name="recID" />
        <FormPassthru form={this} name="duration" />

        <FormStatic form={this} name="startedDate" label={s.conferenceRecording.startedDate} />
        <FormStatic form={this} name="durationDisplay" label={s.lblDuration} />
        <FormText form={this} name="customID" label={s.conferenceRecording.customID} />
      </>;
  }

  render(data) {
    this.getAllFields().forEach(field => {
      this.set(field.name, data[field.name] || '');
    });
  }
}

function BroadcastAddModal({ ctx: { hooks, ctrl } }) {
  let modal;

  let audioUploadControl;

  let loading;
  let selectExistingBody;

  let broadcastQueueForm;

  const hookModal = _modal => {
    modal = _modal;
    hooks.add('addModalState', state => {
      if (state === ADD_MODAL_STATE_LOADING) {
        loading.show();
      } else {
        loading.hide();
      }

      if (state === ADD_MODAL_STATE_UPLOAD) {
        audioUploadControl.render();
        audioUploadControl.clear();
      }

      if (state === ADD_MODAL_STATE_CLOSED) {
        modal.hide();
      } else if (state === ADD_MODAL_STATE_CHOOSE_ACTION) {
        modal.show();
      }
    });
  };

  const onSubmit = e => {
    const { file, description } = e;
    const onUploadProgress = e => audioUploadControl.renderProgress(e);

    ctrl.uploadModalUpload(file, description, onUploadProgress)
      .catch(err => {
        audioUploadControl.showError('ERR_UNKNOWN');
      });
  };

  const onCellButtonClick = e => {
    ctrl.selectModalSelect(e.itemIdx);
  };

  hooks.add('addModalRecording', data => {
    broadcastQueueForm.render(data);
  });

  document.body.appendChild(
    <Modal
      ref={hookModal}
      title={s.Broadcast.enqueue}
      onClose={() => ctrl.addModalDismiss()}
    >
      <div use:hook={hooks.show('addModalState', state => state === ADD_MODAL_STATE_CHOOSE_ACTION)}>
        <div class="modal-body">
          <p>
            <button
              type="button"
              class="btn btn-link btn-link-large"
              onclick={() => ctrl.addModalChooseUpload()}
            >
              {s.Broadcast.uploadExisting}
            </button>
          </p>
          <p>
            <button
              type="button"
              class="btn btn-link btn-link-large"
              onclick={() => ctrl.addModalChooseExisting()}
            >
              {s.Broadcast.selectExisting}
            </button>
          </p>
        </div>
        <div class="modal-footer">
          <div class="btn-toolbar-discrete" use:hook={hooks.show('addModalState', state => state === ADD_MODAL_STATE_CHOOSE_ACTION)}>
            <button type="button" class="btn btn-primary ml-auto" onclick={() => ctrl.addModalDismiss()}>{s.lblCancel}</button>
          </div>
        </div>
      </div>

      <div use:hook={hooks.show('addModalState', state => state === ADD_MODAL_STATE_UPLOAD || state === ADD_MODAL_STATE_UPLOADING)}>
        <div class="modal-body">
          <AudioUploadControl ref={audioUploadControl} showAbortButton={false} onSubmit={onSubmit} />
        </div>
        <div class="modal-footer">
          <div class="btn-toolbar-discrete" use:hook={hooks.show('addModalState', state => state === ADD_MODAL_STATE_UPLOAD)}>
            <button type="button" class="btn btn-primary" onclick={() => ctrl.addModalBack()}>{s.Broadcast.back}</button>

            <button type="button" class="btn btn-primary ml-auto" onclick={() => audioUploadControl.submit()}>{s.lblUpload}</button>
            <button type="button" class="btn btn-primary" onclick={() => ctrl.addModalDismiss()}>{s.lblCancel}</button>
          </div>
          <div class="btn-toolbar-discrete" use:hook={hooks.show('addModalState', state => state === ADD_MODAL_STATE_UPLOADING)}>
            <button type="button" class="btn btn-primary ml-auto" onclick={() => ctrl.addModalDismiss()}>{s.lblCancel}</button>
          </div>
        </div>
      </div>

      <div use:hook={hooks.show('addModalState', state => state === ADD_MODAL_STATE_SELECT || state === ADD_MODAL_STATE_LOADING)}>
        <div class="modal-body" ref={selectExistingBody}>
          <TableToolbar>
            <span class="table-note">
              <BroadcastEnqueueNote />
            </span>

            <SearchInput hooks={hooks} hookProp="selectModalForm" dataProp="search" onSubmit={search => ctrl.selectModalSubmitSearch(search)}  />
          </TableToolbar>

          <SelectModalTable ctx={{ hooks, ctrl }} onCellButtonClick={onCellButtonClick} />
          <PagingToolbar ctrl={ctrl.pagingController} />
        </div>
        <div class="modal-footer">
          <div class="btn-toolbar-discrete">
            <button type="button" class="btn btn-primary" onclick={() => ctrl.addModalBack()}>{s.Broadcast.back}</button>

            <button type="button" class="btn btn-primary ml-auto" onclick={() => ctrl.addModalDismiss()}>{s.lblCancel}</button>
          </div>
        </div>
      </div>

      <form
        use:hook={hooks.show('addModalState', state => state === ADD_MODAL_STATE_ADD_TO_PLAYLIST)}
        onsubmit={wrapSubmitHandler(() => ctrl.addModalAddToQueue(broadcastQueueForm.getAllValues()))}
      >
        <div class="modal-body">
          <div class="form-horizontal">
            <BroadcastEnqueueForm ctrl={ctrl} ref={broadcastQueueForm} />
          </div>
        </div>
        <div class="modal-footer">
          <div class="btn-toolbar-discrete">
            <button type="button" class="btn btn-primary" onclick={() => ctrl.addModalBack()}>{s.Broadcast.back}</button>

            <button type="submit" class="btn btn-primary ml-auto">{s.Broadcast.enqueue}</button>
            <button type="button" class="btn btn-primary" onclick={() => ctrl.addModalDismiss()}>{s.lblCancel}</button>
          </div>
        </div>
      </form>
    </Modal>
  );

  loading = new LoadingIndicator(selectExistingBody, {
    label: s.lblLoading,
  });

  audioUploadControl.addDropzone(modal.bodyAndFooter);
}

class SelectModalTable extends SortableTable {
  constructor({ ctx: { hooks, ctrl }, onCellButtonClick }) {
    super({
      onCellButtonClick,
      className: 'nowrap striped dataTable subpage-table',
      itemKey: 'cdrID',
      noDataString: s.lblNoData,
      onHeaderClick: e => {
        this._sorter.onHeaderClick(e);

        const [ sortProp ] = this._sorter.getSortProps();

        ctrl.selectModalSort(sortProp.colId, sortProp.order);
      },
      columns: [
        {
          id: 'enqueueBroadcast',
          className: 'hover-cell shrink',
          create(cell) {
            return <button type="button" class="btn-table-icon" title={s.Broadcast.enqueue}>{TbIcons.PLUS_CIRCLE}</button>;
          },
        },
        {
          id: 'customID',
          colKey: 'customID',
          className: 'column-customID',
          title: s.conferenceRecording.name,
          sorting: true,
        },
        {
          colKey: 'durationDisplay',
          className: 'text-right shrink',
          title: s.lblDuration,
        },
        {
          id: 'startedDate',
          colKey: 'startedDate',
          className: 'text-right shrink',
          title: s.conferenceRecording.startedDate,
          sorting: true,
        },
      ],
    });

    this._sorter = new Sorter({
      table: this,
    });

    hooks.add('selectModalItems', items => {
      this._sorter.setSortProps(ctrl.selectModalSortProps);
      this.renderSort(this._sorter.getSortProps());
      this.render(items);
    });
  }
}

export function BroadcastEnqueueNote() {
  return (
    <>
      {s.Broadcast.helpPrefix}<span class="tbicon-large">{TbIcons.PLUS_CIRCLE}</span>{s.Broadcast.helpSuffix}
    </>
  );
}
