import Loader from 'Loader';
import { AuthError } from 'CommonExceptions';
import errorReport from 'Error/report';
import Alert from 'Components/Alert';
import { cachedUpdate, toggleClass } from 'Components/domHelpers';
import { Cond } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import { TabsContainer, Tabs } from 'Components/Tabs';

import BridgeInfoTable from './BridgeInfoTable';
import { LoginController } from './LoginController';
import LoginControl from './LoginControl';
import { LoginInfo, OperatorStatus } from './LcmComponents';
import { ConfController } from './ConfController';
import SubConfManager from './SubConfManager';
import SubpageDispatcher from './SubpageDispatcher';
import ModalConfirm from './ModalConfirm';
import WebCallLoader from './WebCallLoader';
import ErrorReportModal from './ErrorReportModal';

import Chat from './Chat';

// subpages
import Lcm from './Lcm';
import Report from './Report';
import ConfDetails from './ConfDetails';
import Settings from './Settings';
import AddressBook from './AddressBook';
import AddressBookEntry from './AddressBookEntry';
import Users from './Users';
import UserID from './UserID';
import Info from './Info';
import Wallet from './Wallet';
import ConferenceRecording from './ConferenceRecording';
import Recordings from './Recordings';
import UploadRecording from './UploadRecording';
import EmailBridgeInfo from './EmailBridgeInfo';
import AccountAdminSettings from './AccountAdminSettings';

import s from './strings';

const MODULE_MAP = {
  lcm                  : Lcm,
  report               : Report,
  confDetails          : ConfDetails,
  settings             : Settings,
  addressBook          : AddressBook,
  addressBookEntry     : AddressBookEntry,
  users                : Users,
  userID               : UserID,
  info                 : Info,
  wallet               : Wallet,
  conferenceRecording  : ConferenceRecording,
  recordings           : Recordings,
  uploadRecording      : UploadRecording,
  emailBridgeInfo      : EmailBridgeInfo,
  accountAdminSettings : AccountAdminSettings,
};

export default class LcmPortal {
  constructor(options) {
    const { portal: portalConfig } = options.moduleConfig;

    const onSubpageChange = tabKey => {
      this._tabs.setActive(tabKey);
    };
    const onDispatchError = err => {
      if (err instanceof AuthError) {
        setTimeout(() => {
          this.loginController.logoutWithError('ERR_AUTHENTICATION');
        }, 0);

        // SubpageDispatcher should ignore
        return true;
      }

      // SubpageDispatcher should handle
      return false;
    };
    this.subpageDispatcher = new SubpageDispatcher({ onSubpageChange, onDispatchError });

    this.ctrl = new ConfController(options.moduleConfig.confController);
    this.ctrl.on('update', () => this.render());
    this.ctrl.on('changeSubConfID', subConfID => this.subpageDispatcher.openSubpage('lcm', { id: subConfID }));

    this.loginController = new LoginController(portalConfig.login);
    this.loginController
      .on('update', () => this.render())
      .on('login', identity => {
        this.ctrl.start(identity);
        this.subpageDispatcher.start();
      })
      .on('logout', () => {
        this.ctrl.stop();
        this.subpageDispatcher.stop();
      });

    this._webCallLoader = new WebCallLoader({
      confController: this.ctrl,
      confConfig: options.moduleConfig.webCallConfApp,
      playerConfig: options.moduleConfig.webCallPlayer,
    });

    this._modalWebCallLogout = new ModalConfirm({
      title: s.lblWebCall,
      message: s.lblWebCallModalLogoutLabel,
      confirmLabel: s.lblHangupAndLogout,
      confirm: () => {
        if (this._webCallLoader.isConnected()) {
          this._webCallLoader.disconnect();
        }

        this.loginController.logout();
      }
    });

    const hooks = this.hooks = new Hooks();
    const hooksLogin = this.hooksLogin = new Hooks();

    this.root =
      <div class="portal-container" class:app-compact={options.compact}>
        <div class="portal-container-content" use:hook={hooksLogin.show('loggedIn')}>
          <div class="app-main" ref={this._appMain}>
            <div class="app-header">
              <div class="logo-container"></div>
              <BridgeInfoTable ctrl={this.ctrl} maskPin hideConferenceID={portalConfig.hideConferenceID} />
              <OperatorStatus hooks={hooks} />
              <Cond test={this._webCallLoader.confAppUI}>
                <div
                  class="webcall-conf-app-container print-hide"
                  use:hook={hooks.show('webCallAllowed')}
                >
                  {this._webCallLoader.confAppUI}
                </div>
              </Cond>
            </div>

            <TabsContainer aria-label={s.LcmPortal.navLabel} hidden={!options.showTabs}>
              <Tabs ref={this._tabs} />
              <button type="button" class="tb-tabs-tab" onclick={() => this._onLogoutClick()}>
                {s.lblLogout}
              </button>
            </TabsContainer>

            <div class="container-fluid">
              {this.subpageDispatcher.root}
              <div class="app-main-subpages" />
              <LoginInfo hooks={hooks} />
            </div>
          </div>
          <Cond test={this.ctrl.chatController}>
            <Chat ctrl={this.ctrl.chatController} />
          </Cond>
        </div>
        <div class="portal-container-panel" use:hook={hooksLogin.hide('loggedIn')}>
          <div class="portal-container-panel-inner">
            <div class="logo-container" />

            <Cond test={portalConfig.noticeMarkup}>
              <Alert type="warning" ref={this._noticeAlert} />
            </Cond>

            <LoginControl ctrl={this.loginController} />
          </div>
        </div>
      </div>;

    document.body.append(this.root);

    if (portalConfig.noticeMarkup)
      this._noticeAlert.innerHTML = portalConfig.noticeMarkup;

    const modules = [
      {
        moduleName: 'lcm',
        config: options.moduleConfig.lcm,
        hash: 'lcm',
        tabKey: 'lcm',
      }
    ];
    const tabs = [];

    options.internalTabs.forEach(tab => {
      const { moduleName, hash, label, parent } = tab;

      if (!MODULE_MAP[moduleName])
        return;

      if (moduleName === 'accountAdminSettings' && !portalConfig.login.useAccountAuth)
        return;

      if (!parent) {
        tabs.push({
          key: moduleName,
          href: `#${hash}`,
          label,
          cssClass: 'rw-only',
          onclick: () => {
            this.subpageDispatcher.openSubpage(moduleName);
          },
        });
      }

      modules.push({
        moduleName,
        config: options.moduleConfig[moduleName] || {},
        hash,
        tabKey: parent || moduleName
      });
    });

    modules.forEach(({moduleName, config, hash, tabKey}) => {
      const module = new MODULE_MAP[moduleName]();
      module.moduleName = moduleName;
      module.tabKey = tabKey;
      module._portal = this;

      this.subpageDispatcher.addSubpage(moduleName, module, hash);

      module.init(config);
    });

    this._tabs.setTabs([
      ...tabs,

      ...options.externalTabs.map(tab => ({
        isLink: true,
        label: tab.label,
        href: tab.url,
        target: '_blank',
      })),

      ...(options.enableReportError && [{
        label: s.lblReportError,
        onclick: () => this._errorReportModal.display(),
      }] || []),

      ...(options.helpURL && [{
        isLink: true,
        label: s.lblHelp,
        href: options.helpURL,
        target: '_blank',
      }] || []),
    ]);

    this._errorReportModal = new ErrorReportModal();
    errorReport.on('preSend', e => {
      const {
        partnerID,
        accountID,
        accountName,
        bridgeID,
        conferenceID,
      } = this.ctrl.authInfo;

      e.reportObject.appData = {
        partnerID,
        accountID,
        accountName,
        bridgeID,
        conferenceID,
      };
    });

    this._subConfManager = new SubConfManager(this.subpageDispatcher, this._tabs, this.ctrl);

    if (options.appMainMaxWidth) {
      this._appMain.style.maxWidth = `${options.appMainMaxWidth}px`;
    }

    this._updaters = {
      logoPath: cachedUpdate(val => {
        Loader.setLogo(val);
      }),
      writable: cachedUpdate(val => {
        toggleClass(document.body, 'view-ro', !val);
      }),
    };
  }

  start() {
    return this.loginController.start();
  }

  isLogoutAllowed() {
    if (this._webCallLoader.isConnected()) {
      this._modalWebCallLogout.display();
      return false;
    }

    return true;
  }

  _onLogoutClick() {
    if (!this.isLogoutAllowed()) return;

    this.loginController.logout();
  }

  render() {
    const { logoPath, writable } = this.ctrl;
    this._updaters.logoPath(logoPath);
    this._updaters.writable(writable);

    this.hooks.run(this.ctrl);
    this.hooksLogin.run(this.loginController.loginState);
  }
}
