import report from './report';

function configure(url, timeout) {
  report.configure(url, timeout);

  window.onerror = function(msg, url, line, col, jsErrorObject) {
    const errorData = {
      message : msg,
      url     : url,
      line    : line,
      col     : null
    };

    if (col !== undefined)
      errorData.col = col;

    report.send('window.onerror', errorData, jsErrorObject);
  };

  window.onunhandledrejection = function(event) {
    let jsErrorObject = null;
    const errorData = {
      eventToString: event.toString(),
      reasonType: null,
    };

    if (event.reason) {
      try {
        errorData.reasonType = typeof event.reason;

        if (errorData.reasonType === 'object') {
          if (event.reason instanceof Error) {
            jsErrorObject = event.reason;
          } else {
            errorData.reason = serializeReasonObject(event.reason);
          }
        } else if (errorData.reasonType !== 'function') {
          errorData.reason = event.reason;
        }
      } catch (err) {
        errorData.message = `error in unhandledrejection handler: ${err.toString()}`;
      }
    }

    report.send('promise unhandledrejection', errorData, jsErrorObject);
  };
}

function serializeReasonObject(reason) {
  try {
    const str = JSON.stringify(reason);
    const obj = JSON.parse(str);
    return obj;
  } catch (err) {
    return {
      msg: `serializeReasonObject() error: ${err.toString()}`,
      reasonKeys: Object.keys(reason),
    };
  }
}

export default {
  setAppName: report.setAppName,
  configure,
};
