import EventEmitter from 'events';

import { AuthError, NotFoundError } from 'CommonExceptions';
import Api from 'Api/Api';
import ApiContext from 'Api/ApiContext';

import AccessMethods from './AccessMethods';
import BroadcastController from './BroadcastController';
import BridgeSettingsController from './BridgeSettingsController';
import ConferencePoller from './ConferencePoller';
import CallCommand from './CallCommand';
import ConfCommand from './ConfCommand';
import ChatController from './ChatController';
import StoredIdentity from './StoredIdentity';

import s from './strings';

export { GAIN_MIN, GAIN_MAX } from './ConfConstants';

const DEFAULT_CONF_SETTINGS = {
  confMode: 'conversation',
  entryChimes: 'chime',
  exitChimes: 'chime',
  locked: 0,
  recordCalls: 0,
};

export class ConfController extends EventEmitter {
  static ACTIVITY_DISCONNECTED = 0;
  static ACTIVITY_HOLD = 1;
  static ACTIVITY_PENDING = 2;
  static ACTIVITY_5 = 3;
  static ACTIVITY_2 = 4;
  static ACTIVITY_0 = 5;

  constructor(config) {
    super();

    this._apiContext = Api.defaultContext;

    this._config = config;
    this._notesFields = config.notesFields;
    this._customColumns = config.customColumns;
    this._enableOperatorWebCall = config.enableOperatorWebCall;

    this._allCalls = [];
    this._mainConfCallCount = 0;
    this._handRaisingSelected = null;
    this._handRaisingPrevSelectedCallID = null;
    this._activeBroadcast = null;
    this._isClientCallConnected = false;

    this._callObservers = new Map();

    this._setCallProps = {
      nameTranscribePosition: false,
      userID: false,
      handRaisedIndexDisplay: false,
    };
    this._setCallProp_test = {
      handRaisedIndexDisplay: val => val !== null,
    };
    this._totals = {};

    config.colConfig.forEach(col => {
      const { type, prop, sum } = col;
      if (type === 'custom') {
        this._setCallProps[prop] = false;
        if (sum)
          this._totals[prop] = 0;
      }
    });

    this._subConfID = null;

    this._poller = new ConferencePoller();
    this._poller.on('update', () => this._onPollerUpdate());

    if (config.enableChat) {
      this.chatController = new ChatController();

      this.chatController.on('nameChange', e => {
        const { name } = e;
        StoredIdentity.name = name;
        this._identity.name = name;
        this.chatController.updateName(name);

        this._poller.sendRequest(true);
      });
    }

    this.broadcastController = new BroadcastController();
    this.broadcastController.on('pollRequested', () => this.pollIfInactive());

    this.bridgeSettingsController = new BridgeSettingsController(config.settingsController);

    this.setDataInactive();

    setInterval(() => this._tick(), 1000);
  }

  _setBridgeData(bridgeData) {
    this._bridgeData = bridgeData;
    this._poller.setTimezone(this.timezone);
    this._onUpdate();
  }

  _onUpdate() {
    if (!this._bridgeData)
      throw new Error('_onUpdate cannot happen without _setBridgeData being called first');

    const {
      writable,
    } = this;
    this.broadcastController.update({
      writable,
      activeBroadcast: this._activeBroadcast,
    });

    const state = this._getEffectiveState();
    const bridgeActive = this._bridgeData.activeFlag;

    if (bridgeActive && state === 'active') {
      const calls = [];
      this._allCalls.forEach(cur => {
        let add = false;
        if (cur.subConf !== undefined) {
          // current call is in a subconf, it has to match the current
          // subConfID
          add = cur.subConf === this._subConfID;
        } else {
          // current call is in main conf, filter it if the view is for
          // a subconference
          add = this._subConfID === null;
        }

        if (add) {
          calls.push(cur);
        }
      });

      const { handRaisingSelected } = this;
      const selectedIndex = handRaisingSelected && handRaisingSelected.call.handRaisedIndexSorted;
      calls.forEach(call => {
        const { handRaisedIndexSorted } = call;
        if (handRaisedIndexSorted && handRaisedIndexSorted === selectedIndex) {
          call.handRaisedIndexDisplay = 0;
        } else if (handRaisedIndexSorted) {
          call.handRaisedIndexDisplay = selectedIndex && handRaisedIndexSorted > selectedIndex
            ? handRaisedIndexSorted - 1
            : handRaisedIndexSorted;
        } else {
          call.handRaisedIndexDisplay = null;
        }
      });

      const result = this._poller._lastResult;
      const confState = this._subConfID === null
        ? result
        : this.curSubConfData;

      const {
        status: confStatus,
        nameTranscribeCount = 0,
        startedDateDisplay,
        duration,
        dataPerm: comment = '',
      } = confState;

      const confPending =
        this._subConfID === null && confState.status === 'pending' &&
        confState.confStart !== 'instant' && confState.confStart !== 'secondCaller';

      let settings = null;
      if (this._subConfID === null) {
        const {
          confMode,
          entryChimes,
          exitChimes,
          locked,
          recordCalls,
        } = result;
        settings = {
          confMode,
          entryChimes,
          exitChimes,
          locked,
          recordCalls,
        };
      }

      this.setData({
        state,
        retryWaitSecs: 0,
        confStatus,
        confPending,
        callerCount: calls.length,
        totalCallerCount: this._allCalls.length,
        subConfCount: Object.keys(this.subConfs).length,
        nameTranscribeCount,
        startedDateDisplay,
        duration,
        comment,
        settings,

        calls,
      });
    } else {
      this.setDataInactive(state);
    }
  }

  _onPollerUpdate() {
    if (!this._bridgeData)
      throw new Error('_onPollerUpdate cannot happen without _setBridgeData being called first');

    this._allCalls = [];
    this._mainConfCallCount = 0;
    this._handRaisingSelected = null;
    this._handRaisingPrevSelectedCallID = null;
    this._activeBroadcast = null;
    this._isClientCallConnected = false;

    const foundObservers = [];

    if (this._poller._state === 'active') {
      const result = this._poller._lastResult;

      const {
        selectedCall,
        prevSelectedCall,
      } = result;
      const selectedCallID = selectedCall && selectedCall.callID || null;
      const prevSelectedCallID = prevSelectedCall && prevSelectedCall.callID || null;
      let prevSelectedFound = false;

      this._allCalls = result.calls && result.calls.call || [];
      this._allCalls.forEach(call => {
        this._setCallFields(call);
        call.isHandSelected = call.callID === selectedCallID;

        if (!call.subConf) {
          this._mainConfCallCount++;
        }

        if (call.isHandSelected) {
          this._handRaisingSelected = {
            call,
            origMuted: parseInt(selectedCall.muted),
          };
        }

        if (!call.disconnected && call.callID === prevSelectedCallID) {
          prevSelectedFound = true;
        }

        if (!this._activeBroadcast && call.isBroadcast) {
          const {
            participantID,
            callID,
            name: description,
            dataPerm: {
              broadcast: {
                broadcastID = null,
                duration,
              },
            },
            stream,
            inGain,
          } = call;

          const durationMS = parseInt(duration, 10);
          const positionMS = parseInt(stream.state.position, 10);

          this._activeBroadcast = {
            broadcastID,
            participantID,
            callID,
            description,
            duration: Math.floor(durationMS / 1000),
            position: Math.floor(positionMS / 1000),
            isPlaying: !!stream.state.read,
            inGain,
          };
        }

        if (call.isClientCall) {
          this._isClientCallConnected = true;
        }

        const { participantID } = call;
        const mapEntry = this._callObservers.get(participantID);
        if (mapEntry) {
          mapEntry.observer.update(call);
          foundObservers.push(participantID);
        }
      });

      if (prevSelectedFound) {
        this._handRaisingPrevSelectedCallID = prevSelectedCallID;
      }
    }

    this._callObservers.forEach((mapEntry, participantID) => {
      if (!foundObservers.includes(participantID)) {
        mapEntry.observer.disconnect();
        this._callObservers.delete(participantID);
      }
    });

    if (this.chatController) {
      const chats = this._poller._lastResult.chats || [];

      this.chatController.update({
        isActive: this._poller._state === 'active',
        chats,
      });
    }

    this._onUpdate();
  }

  setDataInactive(state = 'pending') {
    const bridgeActive = this._bridgeData
      ? this._bridgeData.activeFlag
      : true;
    this.setData({
      state: bridgeActive ? state : 'inactive',
      retryWaitSecs: this._poller._retryCtr,
      confStatus: 'pending',
      confPending: false,
      callerCount: null,
      totalCallerCount: null,
      subConfCount: null,
      nameTranscribeCount: 0,
      startedDateDisplay: null,
      duration: null,
      comment: null,
      settings: this._bridgeData,

      calls: [],
    });
  }

  setData({ state, retryWaitSecs, confStatus, confPending, callerCount, totalCallerCount, subConfCount, nameTranscribeCount, startedDateDisplay, duration, comment, settings, calls }) {
    this._scope = this._apiContext.getScope();
    this._state = state;
    this._retryWaitSecs = retryWaitSecs;
    this._confStatus = confStatus;
    this._confPending = confPending;
    this._callerCount = callerCount;
    this._totalCallerCount = totalCallerCount;
    this._subConfCount = subConfCount;
    this._nameTranscribeCount = nameTranscribeCount;
    this._startedDateDisplay = startedDateDisplay;
    this._duration = duration;
    this._comment = comment;

    if (settings) {
      const {
        confMode,
        entryChimes,
        exitChimes,
        locked,
        recordCalls,
      } = settings;

      this._settings = {
        confMode,
        entryChimes,
        exitChimes,
        locked,
        recordCalls,
      };
    } else {
      this._settings = DEFAULT_CONF_SETTINGS;
    }

    // reset time offset
    this._localTimeOffset = 0;

    if (!this.isConfActive) {
      this._lastUpdateTime = null;
      this._lastDuration = null;
      this._namePlayingParticipantID = null;
    } else {
      const d = new Date();
      this._lastUpdateTime = d.getTime();
    }

    for (let prop in this._setCallProps)
      this._setCallProps[prop] = false;
    for (let prop in this._totals)
      this._totals[prop] = 0;

    calls.forEach(call => {
      const oldCall = this._calls && this.getCallByParticipantID(call.participantID);
      call.actionSelect = oldCall && !call.disconnected
        ? oldCall.actionSelect
        : 0;
      call.handRaisingChecked = oldCall && !call.disconnected && call.handRaisedIndexDisplay
        ? oldCall.handRaisingChecked
        : 0;

      call.activity = this._getActivity(call);

      for (let prop in this._setCallProps) {
        if (this._setCallProp_test[prop]) {
          // special test function defined for this prop
          if (this._setCallProp_test[prop](call[prop]))
            this._setCallProps[prop] = true;
        } else {
          // default test function - check truthiness
          if (call[prop])
            this._setCallProps[prop] = true;
        }
      }
      for (let prop in this._totals)
        this._totals[prop] += parseInt(call[prop], 10) || 0;
    });

    this._calls = calls;

    this.emit('update');
  }

  setSubConfID(subConfID) {
    if (this._subConfID === subConfID) return;

    this._subConfID = subConfID;
    this._lastDuration = null;
    this._onUpdate();
  }

  start(loginData) {
    const { identity, bridge } = loginData;

    this._identity = identity;

    this._setBridgeData(bridge);

    this._poller.start(identity);
    if (this.chatController)
      this.chatController.updateName(identity.name);

    this.broadcastController.start();
  }

  stop() {
    this._poller.stop();
    this._bridgeData = null;
    this._identity = null;
    this._subConfID = null;
    this.setDataInactive();

    this._apiContext.abortAll();

    if (this.chatController) {
      // create one-off ApiContext with current authToken before
      // authToken is cleared from defaultContext and ClientStorage
      const ctx = new ApiContext('stopDataSession', this._config.apiConfig);
      ctx.setAuthParams({
        authToken: {
          token: this._apiContext.getAuthToken(),
        },
      });

      Api.get('LCM', 'stopDataSession', {}, {}, ctx)
        .catch(err => {
          // swallow errors
        });
    }

    this.broadcastController.stop();
  }

  retry() {
    this._poller.retry();
  }

  pollIfInactive() {
    // do nothing when not logged in
    if (!this._identity)
      return;

    if (this._poller._state === 'pending')
      this._poller.sendRequest();
  }

  fetchBridgeData() {
    const params = {
      dateFormat: 'longEnglishDate'
    };

    return Api.get('Bridge', 'getBridges', params, { cancelID: 'BridgeObjectFetch' })
      .then(res => {
        const bridge = res.bridge && res.bridge[0];
        if (!bridge)
          throw new AuthError('no bridge result');

        this._setBridgeData(bridge);

        return bridge;
      });
  }

  createCallObserver(participantID) {
    const existing = this._callObservers.get(participantID);
    if (existing) {
      existing.refCount++;
      return existing.observer;
    }

    const call = this._getCallByParticipantIDOrFail(participantID);
    const observer = new CallObserver(call);
    this._callObservers.set(participantID, {
      refCount: 1,
      observer,
    });

    return observer;
  }

  destroyCallObserver(participantID) {
    const obj = this._callObservers.get(participantID);
    if (!obj) {
      return;
    }

    obj.refCount--;
    if (obj.refCount <= 0) {
      this._callObservers.delete(participantID);
    }
  }

  get adminID() {
    if (this._identity)
      return this._identity.adminID;

    return null;
  }

  get features() {
    return {
      subConfs: !!this._config.enableSubconferences,
      showCallerID: !!this._scope.showCallerID,
      recording: !!(!this._config.disableRecording && this._bridgeData && this._bridgeData.recordingAllowedFlag),
      broadcast: !!(this._config.enableBroadcast && this._bridgeData && this._bridgeData.enableBroadcast),

      userIDEnabled: !!(this._bridgeData && this._bridgeData.validateUserID !== 0),
      userIDRequired: !!(this._bridgeData && [ 1, 3 ].includes(this._bridgeData.validateUserID)),
    };
  }

  get bridgeInfo() {
    if (this._bridgeData) {
      const {
        conferenceIDFormatted,
        name,
        pin,
        tollNumberFormatted,
        tollFreeNumberFormatted,
      } = this._bridgeData;

      return {
        conferenceIDFormatted,
        name,
        pin,
        tollNumberFormatted,
        tollFreeNumberFormatted,
      };
    } else {
      return {
        conferenceIDFormatted: null,
        name: null,
        pin: null,
        tollNumberFormatted: null,
        tollFreeNumberFormatted: null,
      };
    }
  }

  get state() {
    return this._state;
  }

  get isConfActive() {
    return this._state === 'active';
  }

  get retryWaitSecs() {
    return this._retryWaitSecs;
  }

  get mainConfCounts() {
    const { handRaisedCount = 0 } = this._poller._lastResult;
    return {
      callCount: this._mainConfCallCount,
      handRaisedCount,
    };
  }

  get subConfID() {
    return this._subConfID;
  }

  get subConfs() {
    const result = this._poller._lastResult;
    const subConfs = result.subConfs && result.subConfs.subConf || [];

    if (this._subConfID && !subConfs.find(subConf => subConf.name === this._subConfID)) {
      // if subConfID is set but that subConf is not in the response, add a placeholder
      subConfs.push({
        name: this._subConfID,
        placeholder: true,
      });
    }

    return subConfs;
  }

  get curSubConfData() {
    if (!this._subConfID) return null;
    return this.subConfs.find(cur => cur.name === this._subConfID);
  }

  get isSubConfActive() {
    return this._subConfID !== null;
  }

  get confPending() {
    return this._confPending;
  }

  get callerCountDisplay() {
    let ret = this._callerCount;
    if (this._subConfCount) {
      ret += ` / ${this._totalCallerCount}`;
    }

    return ret;
  }

  get nameTranscribeCount() {
    return this._nameTranscribeCount;
  }

  get startedDateDisplay() {
    return this._startedDateDisplay;
  }

  get duration() {
    if (this._duration === null) return null;

    const duration = this._duration + this._localTimeOffset;

    if (this._lastDuration !== null && this._lastDuration > duration)
      return this._lastDuration;

    this._lastDuration = duration;
    return duration;
  }

  get comment() {
    return this._comment;
  }

  get settings() {
    return {
      ...this._settings,
    };
  }

  get confMode() {
    if (!this.isConfActive) return null;
    return this._settings.confMode;
  }

  get locked() {
    if (!this.isConfActive) return null;
    return this._settings.locked;
  }

  get recordCalls() {
    if (!this.isConfActive) return null;
    return this._settings.recordCalls;
  }

  setConfSetting(name, value) {
    let command;
    switch (name) {
    case 'confMode':
      command = 'setMode';
      break;
    case 'locked':
      command = 'setLocked';
      break;
    case 'entryChimes':
      command = 'setEntryMode';
      break;
    case 'exitChimes':
      command = 'setExitMode';
      break;
    case 'recordCalls':
      command = 'setRecording';
      break;

    default:
      throw new Error(`unknown setting ${name}`);
    }

    ConfCommand.send(command, value);
  }

  get writable() {
    return !!this._scope.write;
  }

  get canManageHands() {
    const { write, raisedHandMove } = this._scope;
    return !!(write || raisedHandMove);
  }

  get makeCallAllowed() {
    return !!(!this._config.disableMakeCall && this._bridgeData && this._bridgeData.makeCallAllowedFlag);
  }

  get identityName() {
    if (!this._identity)
      return null;

    return this._identity.name;
  }

  get operatorFlag() {
    return !!(this._identity && this._identity.operatorFlag);
  }

  get operatorLoginCode() {
    if (!this._identity)
      return null;

    return this._identity.operatorID;
  }

  get isClientCallConnected() {
    return this._isClientCallConnected;
  }

  get webCallAllowed() {
    return !!(this._scope.webCall && !(this.operatorFlag && !this._enableOperatorWebCall));
  }

  get setCallProps() {
    return  {
      ...this._setCallProps,
    };
  }

  get totals() {
    return {
      ...this._totals,
    };
  }

  getCalls(filterFunc  = null, compareFunc = null) {
    // shallow clone
    let items = this._calls.slice();

    if (filterFunc)
      items = items.filter(item => filterFunc(item));
    if (compareFunc)
      items = items.sort((a, b) => compareFunc(a, b));

    return items;
  }

  get hands() {
    const { writable, handRaisingSelected } = this;
    return {
      selected: handRaisingSelected,
      isSelectAllowed: !!(writable && !this.isSubConfActive),
      showSelectionActions: !!(writable && handRaisingSelected),
    };
  }

  get handRaisingSelected() {
    return this._subConfID === null
      ? this._handRaisingSelected
      : null;
  }

  get handRaisingPrevSelectedCallID() {
    return this._subConfID === null
      ? this._handRaisingPrevSelectedCallID
      : null;
  }

  getSelectedCalls(selectProp) {
    return this._calls.filter(call => call[selectProp]);
  }

  getCallByParticipantID(participantID) {
    return this._calls.find(call => call.participantID === participantID);
  }

  _getCallByParticipantIDOrFail(participantID) {
    const call = this.getCallByParticipantID(participantID);
    if (!call) {
      throw new Error(`participantID ${participantID} not found`);
    }

    return call;
  }

  get namePlayingParticipantID() {
    return this._namePlayingParticipantID;
  }

  set namePlayingParticipantID(participantID) {
    this._namePlayingParticipantID = participantID;
    this.emit('update');
  }

  get timezone() {
    return this._bridgeData && this._bridgeData.timezone || null;
  }

  get authInfo() {
    const {
      accountID = null,
      accountName = null,
      bridgeID = null,
      conferenceID = null,
      pin = null,
    } = this._bridgeData || {};

    return {
      partnerID: this._config.partnerID,
      accountID,
      accountName,
      bridgeID,
      conferenceID,
      pin,
    };
  }

  get lastLoginIP() {
    return this._bridgeData && this._bridgeData.lastLoginIP || null;
  }

  get lastLoginDateLocal() {
    return this._bridgeData && this._bridgeData.lastLoginDateLocal || null;
  }

  get logoPath() {
    return this._bridgeData && this._bridgeData.logoPath || null;
  }

  get notesFields() {
    return this._notesFields;
  }

  get notesFieldsPermissions() {
    const perms = {};

    if (this._notesFields) {
      for (let name in this._notesFields) {
        const opts = this._notesFields[name];

        if (this.operatorFlag) {
          perms[name] = {
            read: true,
            write: !!opts.allowEdit,
          };
        } else {
          perms[name] = {
            read: !!opts.hostReadable,
            write: !!(opts.hostReadable && opts.hostWritable),
          };
        }
      }
    }

    return perms;
  }

  disconnectCall(participantID) {
    return Promise.resolve()
      .then(() => {
        const call = this.getCallByParticipantID(participantID);
        if (!call || call.disconnected)
          throw new NotFoundError();

        return call.callID;
      })
      .then(callID => this._sendCallCommand('disconnect', callID, 1));
  }

  getSelectState(selectProp, filterFunc = null) {
    let empty = true;
    let allSelected = true;
    let someSelected = false;
    let count = 0;

    this._calls.forEach(call => {
      if (call.disconnected)
        return;

      if (filterFunc && !filterFunc(call))
        return;

      empty = false;

      if (!call[selectProp]) {
        allSelected = false;
      } else {
        someSelected = true;
        count++;
      }
    });

    allSelected = allSelected && !empty;

    return {
      allSelected,
      someSelected,
      count,
    };
  }

  select(selectProp, type, filterFunc = null) {
    this._calls.forEach(call => {
      if (filterFunc && !filterFunc(call))
        return;

      let selected = false;

      if (!call.disconnected) {
        switch (type) {
        case 'all':
          selected = true;
          break;

        case 'starred':
          selected = call.starred;
          break;

        case 'hosts':
          selected = call.host;
          break;

        case 'participants':
          selected = !call.host;
          break;

        case 'raisedHands':
          selected = call.handRaisedIndexDisplay !== null;
          break;

        case 'onHold':
          selected = call.hold;
          break;
        }
      }

      call[selectProp] = selected ? 1 : 0;
    });

    this.emit('update');
  }

  toggleCallProperty(participantID, property) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    const value = call[property] ? 0 : 1;

    call[property] = value;
    this.emit('update');

    const { callID } = call;
    switch (property) {
    case 'starred':
      CallCommand.setStar(callID, value);
      break;
    case 'host':
      CallCommand.setHost(callID, value);
      break;
    case 'muted':
      CallCommand.setMute(callID, value);
      break;
    }
  }

  setSelectedMute(selectProp, mute) {
    const selected = this.getSelectedCalls(selectProp);
    const val = mute ? 1 : 0;
    if (selected.length) {
      CallCommand.setMute(selected.map(call => call.callID), val);
    } else {
      ConfCommand.setMute(this._subConfID, val);
    }
  }

  unstarAll() {
    const calls = this._calls.filter(call => !call.disconnected && call.starred);
    CallCommand.setStar(calls.map(call => call.callID), 0);
  }

  endConference() {
    const subConfID = this._subConfID;

    if (subConfID === null) {
      ConfCommand.disconnect();
    } else {
      ConfCommand.mergeSubConf(subConfID);

      this.emit('changeSubConfID', null);
    }
  }

  handSelect(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.raisedHandSelect(call.callID);
  }

  handRaise(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.raisedHandRaise(call.callID);
  }

  handLower(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.raisedHandLower(call.callID);
  }

  handLowerSelected(selectProp) {
    const selected = this.getSelectedCalls(selectProp);
    CallCommand.raisedHandLower(selected.map(call => call.callID));
  }

  handLowerAll() {
    ConfCommand.lowerAllHands(this._subConfID);
  }

  handReselectLast() {
    const callID = this.handRaisingPrevSelectedCallID;
    if (callID) {
      CallCommand.raisedHandSelect(callID);
    }
  }

  handMove(participantID, direction, targetParticipantID = null) {
    const call = this._getCallByParticipantIDOrFail(participantID);

    if ((direction === 'before' || direction === 'after') && !targetParticipantID)
      throw new Error('targetParticipantID required');

    let handRaisedIndex = null;
    if (targetParticipantID) {
      const targetCall = this._getCallByParticipantIDOrFail(targetParticipantID);

      handRaisedIndex = targetCall.handRaisedIndex;
      if (handRaisedIndex === null || handRaisedIndex === undefined)
        throw new Error('targetParticipantID invalid');
    }

    CallCommand.raisedHandMove(call.callID, direction, handRaisedIndex);
  }

  setInGain(participantID, inGain) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    this._sendCallCommand('setInGain', call.callID, inGain);
  }

  handMoveMultiple(calls, direction) {
    let flip = false;
    switch (direction) {
    case 'top':
      flip = true;
      break;
    case 'bottom':
      break;
    default:
      throw new Error('invalid direction');
    }

    calls.sort((a, b) => {
      if (a.handRaisedIndexDisplay > b.handRaisedIndexDisplay)
        return flip ? -1 : 1;

      return flip ? 1 : -1;
    });

    const promises = calls.reduce((promise, call) => {
      return promise.then(() => this._sendCallCommand('raisedHandMove', call.callID, direction));
    }, Promise.resolve());

    promises.catch(err => {
    });
  }

  talkToOperator(target) {
    const opCall = this._allCalls.find(call => call.isClientCall);
    if (!opCall) return;

    let subConfID = 'Op';
    if (this._identity.operatorID)
      subConfID = `${subConfID}_${this._identity.operatorID}`;

    const params = {
      setMute: 0,
    };

    CallCommand.setSubConf(target, subConfID, params);
    CallCommand.setSubConf(opCall.callID, subConfID, params);

    this.emit('changeSubConfID', subConfID);
  }

  sendToHelpQueue(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.sendToHelpQueue(call.callID);
  }

  sendToEnterQueue(participantID) {
    const call = this._getCallByParticipantIDOrFail(participantID);
    CallCommand.sendToEnterQueue(call.callID);
  }

  sendToSubConf(targets, subConfID, options = {}) {
    const {
      muted = false,
      muteLocked = false,
      hold = false,
    } = options;

    const params = {
      muted,
      muteLocked,
      hold,
    };

    CallCommand.setSubConf(targets, subConfID, params);
  }

  saveCallerInfo(callID, data) {
    const { name, notesType, notes, notesObject } = data;

    return Api.get('LCM', 'setCallerInfo', {
      callID,
      name,
      notesType,
      notes: notesType === 'string'
        ? notes
        : notesObject,
    });
  }

  getNameTranscribe() {
    const { timezone } = this;
    const params = {
      ...(timezone && { timezone }),
    };

    return Api.get('LCM', 'getNameTranscribe', params)
      .then(res => {
        const call = res.nameTranscribeCall;
        this._setCallFields(call);
        return call;
      });
  }

  putNameTranscribe(callID) {
    return Api.get('LCM', 'putNameTranscribe', { callID });
  }

  _sendCallCommand(command, callID, value, params) {
    const reqParams = {
      command,
      callID,
      value,
    };

    if (params)
      reqParams.params = params;

    return Api.get('LCM', 'changeConferenceCall', reqParams);
  }

  onWebCallConfUpdate(event) {
    this._webCallLastCallID = event && event.callID;
    this.pollIfInactive();
  }

  _getActivity(call) {
    const { disconnected, hold, lastTalked } = call;
    if (disconnected)
      return ConfController.ACTIVITY_DISCONNECTED;

    if (hold)
      return ConfController.ACTIVITY_HOLD;

    if (this._confStatus === 'pending')
      return ConfController.ACTIVITY_PENDING;

    if (lastTalked === null || lastTalked > 5000)
      return ConfController.ACTIVITY_5;

    if (lastTalked > 2000)
      return ConfController.ACTIVITY_2;

    return ConfController.ACTIVITY_0;
  }

  _tick() {
    if (this._apiContext.checkLoggedOut()) return;

    if (!this.isConfActive) return;

    this._localTimeOffset++;

    const d = new Date();
    const timeDiff = d.getTime() - this._lastUpdateTime;

    this._calls.forEach(call => {
      let { lastTalked } = call;

      if (lastTalked === null || lastTalked === undefined) {
        return;
      }

      if (lastTalked / 1000 < 2) {
        return;
      }

      call.lastTalked += timeDiff;
      call.activity = this._getActivity(call);
    });

    this.emit('update');
  }

  _getEffectiveState() {
    const state = this._poller._state;

    // pending and error states apply to all confs/subconfs
    if (state !== 'active') {
      return state;
    }

    // override 'active' state to 'pending' if a subConfID is
    // currently set and there is no cached status for it or it is a
    // placeholder
    const { curSubConfData } = this;
    if (curSubConfData && curSubConfData.placeholder) {
      return 'pending';
    }

    return 'active';
  }

  _setCallFields(call) {
    const { operatorFlag } = this;

    var pos = call.fromAddress.lastIndexOf(':');
    if (pos != -1)
      call.fromAddress =  call.fromAddress.substr(0, pos);

    if (!call.name)
      call.name = s.lblUnknown;

    call.number          = call.fromNumber;
    call.numberFormatted = call.fromNumberFormatted;

    if (call.number === null)
      call.numberFormatted = s.lblUnknown;

    call.callerIDDisplay = call.callerID;
    if (call.accessMethod != AccessMethods.PSTN) {
      call.callerIDDisplay = call.accessMethodDisplay + ' - ' + call.callerIDDisplay;
    }

    if (call.hold === undefined)
      call.hold = 0;

    call.durationMinutes = Math.floor(call.duration / 60);

    // munge notesType/notes/notesObject/notesLabel
    call.notesLabel = '';

    let notes = null;
    let notesObject = null;

    if (this._notesFields) {
      call.notesType = 'object';
      if (call.notes && typeof call.notes === 'object') {
        notesObject = call.notes;
        const showFields = [];

        for (let field in this._notesFields) {
          const opts = this._notesFields[field];

          if (notesObject[field] && ((operatorFlag && opts.operatorShowInHandQueue) || (!operatorFlag && opts.hostShowInHandQueue)))
            showFields.push(field);
        }

        if (showFields.length) {
          call.notesLabel +=
            ' [' + showFields.map(_ => notesObject[_]).join(' ') + ']';
        }
      }
    } else {
      call.notesType = 'string';
      if (call.notes && typeof call.notes === 'string')
        notes = call.notes;
    }
    call.notes = notes;
    call.notesObject = notesObject;

    call.callerNameDisplay = call.name + call.notesLabel;
    call.callerNameCallerID = `${call.callerNameDisplay} ${call.callerIDDisplay}`;

    call.starred = false;
    if (call.callData && call.callData.starred == 1)
      call.starred = true;

    if (call.userID === undefined)
      call.userID = null;

    if (call.lastTalked === undefined)
      call.lastTalked = null;

    if (call.disconnected) {
      call.starred      = 0;
      call.muted        = 0;
      call.host         = 0;
    }

    if (call.muted === undefined)
      call.muted = 0;

    if (call.nameRecorded === undefined)
      call.nameRecorded = 0;

    if (call.nameRecorded && !call.nameTranscribePosition)
      call.nameTranscribePosition = Infinity;

    call.isClientCall =
      call.callID === this._webCallLastCallID ||
      (call.operator && call.operator === this._identity.operatorID);

    const { write } = this._scope;
    const { canManageHands } = this;
    call.isActionAllowed = !(call.disconnected || !write);
    call.isHandRaisingActionAllowed = !(call.disconnected || !canManageHands);

    call.isBroadcast = !!(
      !call.disconnected &&
      call.broadcast &&
      call.dataPerm &&
      call.dataPerm.broadcast &&
      call.stream &&
      call.stream.state
    );

    if (operatorFlag) {
      call.operatorHelpRequested = !!call.operatorHelpRequested;
    } else {
      call.operatorHelpRequested = false;
    }

    // setup custom columns
    this._customColumns.forEach(def => {
      let val = '';

      switch (def.type) {
      case 'temp':
        if (call.callData && def.name in call.callData)
          val = call.callData[def.name];

        break;

      case 'perm':
        if (call.dataPerm && def.name in call.dataPerm)
          val = call.dataPerm[def.name];

        break;

      case 'notes':
        if (call.notesObject && def.name in call.notesObject)
          val = call.notesObject[def.name];

        break;

      case 'callProperty':
        if (def.name in call)
          val = call[def.name];

        break;
      }

      call[def.keyName] = val;
    });
  }
}

class CallObserver extends EventEmitter {
  constructor(call) {
    super();
    this._call = call;
    this._disconnected = false;
  }

  update(call) {
    this._call = call;
    this.emit('update');
  }

  disconnect() {
    this._disconnected = true;
    this.emit('update');
  }

  get participantID() {
    return this._call.participantID;
  }

  get subConfID() {
    return this._call.subConf;
  }

  get disconnected() {
    return !!(this._disconnected || this._call.disconnected);
  }

  get inGain() {
    return this._call.inGain;
  }

  get outGain() {
    return this._call.outGain;
  }
}
