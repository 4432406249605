import { toggleClass } from 'Components/domHelpers';
import TbIcons from 'Components/TbIcons';

import { ConfController } from './ConfController';
import { SortableTable, Sorter } from './Tables';
import { ToggleButton } from './LcmComponents';

import s from './strings';

function TbIcon(props) {
  return <span class={`tbicon-${props.children}`}></span>;
}

function CellToggleButton({ cell, fieldName, iconType = 'checkbox' }) {
  const checked = cell[fieldName];
  return <ToggleButton checked={checked} disabled={!cell.isActionAllowed} iconType={iconType} />;
}

export default class CallListTable extends SortableTable {
  constructor({ ref, ctrl, onUpdate, colConfig, fillerRowTotal, onCellButtonClick }) {
    const colDefs = {
      actionSelect: {
        colKey: [ 'isActionAllowed', 'actionSelect' ],
        className: 'hover-cell actionSelect',
        visibility: ({ writable }) => writable,
        create(cell) {
          return <CellToggleButton cell={cell} fieldName="actionSelect" />;
        },
      },
      starred: {
        colKey: [ 'isActionAllowed', 'starred' ],
        sorting: {
          prop: 'starred',
          reverse: true,
        },
        className: 'hover-cell shrink',
        visibility: ({ writable }) => writable,
        create(cell) {
          return <CellToggleButton cell={cell} fieldName="starred" iconType="star" />;
        },
      },
      callerID: {
        colKey: 'callerIDDisplay',
        extraKey: 'writable',
        title: s.lblCallerID,
        sorting: {
          prop: 'callerIDDisplay',
          compareType: Sorter.SORT_COMPARE_TYPE_LOCALE,
        },
        className: 'hover-cell text-left callerID context-menu btnAction',
        visibility: ({ showCallerID }) => showCallerID,
        create(callerIDDisplay, writable) {
          if (!writable) {
            return <div class="label-only">{callerIDDisplay}</div>;
          }

          return (
            <>
              <button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>
                  <span class="content">{callerIDDisplay}</span>
                </span>
              </button>
              <ul class="dropdown-menu">
              </ul>
            </>
          );
        },
      },
      callerName: {
        colKey: [ 'callerNameDisplay', 'isClientCall', 'operatorHelpRequested' ],
        extraKey: 'writable',
        title: s.lblCallerName,
        sorting: {
          prop: 'callerNameDisplay',
          compareType: Sorter.SORT_COMPARE_TYPE_LOCALE,
        },
        className: 'hover-cell text-left caller-name',
        create(cell, writable) {
          const clientCallLabel = cell.isClientCall
            ? <strong>{s.lblClientCall}</strong>
            : '';

          const operatorHelpRequestedIcon = cell.operatorHelpRequested
            ? <span class="tbicon-large">{TbIcons.ALERT_CIRCLE}</span>
            : '';

          if (!writable) {
            return (
              <div class="label-only">
                {clientCallLabel} {cell.callerNameDisplay}{operatorHelpRequestedIcon}
              </div>
            );
          }

          return (
            <button type="button">
              {clientCallLabel} {cell.callerNameDisplay}{operatorHelpRequestedIcon}
            </button>
          );
        },
      },
      nameRecorded: {
        colKey: [ 'nameRecorded', 'nameTranscribePosition' ],
        extraKey: 'namePlayingParticipantID',
        sorting: {
          prop: 'nameTranscribePosition',
          nullUndefInfinity: true,
        },
        className: 'hover-cell shrink',
        visibility: ({ setCallProps: { nameTranscribePosition }}) => nameTranscribePosition,
        create(cell, namePlayingParticipantID, participantID) {
          const { nameRecorded, nameTranscribePosition } = cell;

          if (!nameTranscribePosition) {
            return '';
          }

          const label = nameTranscribePosition !== Infinity
            ? nameTranscribePosition
            : '';

          if (!nameRecorded) {
            return (
              <div class="icon-label">
                <div class="tbicon-large" />
                <div class="label">{label}</div>
              </div>
            );
          }

          return (
            <button type="button"  class="icon-label" title={s.lblPlayRecordedName}>
              <div class="tbicon-large">{participantID === namePlayingParticipantID ? TbIcons.STOP_CIRCLE : TbIcons.PLAY_CIRCLE}</div>
              <div class="label">{label}</div>
            </button>
          );
        },
      },
      userID: {
        colKey: 'userID',
        title: s.lblUserID,
        sorting: {
          prop: 'userID',
          compareType: Sorter.SORT_COMPARE_TYPE_LOCALE,
        },
        className: 'shrink',
        visibility: ({ setCallProps: { userID }}) => userID,
      },
      location: {
        colKey: 'location',
        title: s.lblLocation,
        sorting: {
          prop: 'location',
          compareType: Sorter.SORT_COMPARE_TYPE_LOCALE,
        },
        className: 'location shrink',
      },
      started: {
        colKey: 'startedDateDisplay',
        title: s.lblArrived,
        sorting: {
          prop: 'started',
        },
        className: 'text-right shrink',
      },
      durationMinutes: {
        colKey: 'durationMinutes',
        title: s.lblDuration,
        sorting: {
          prop: 'duration',
        },
        className: 'text-right shrink',
        create(cell) {
          return `${cell} ${s.lblMin}`;
        },
      },
      activity: {
        colKey: 'activity',
        title: s.lblActivity,
        sorting: {
          prop: 'lastTalked',
          nullUndefInfinity: true,
        },
        className: 'shrink',
        create(cell) {
          let icon;
          switch (cell) {
          case ConfController.ACTIVITY_DISCONNECTED:
            icon = <TbIcon>call-disconnected</TbIcon>;
            break;

          case ConfController.ACTIVITY_HOLD:
            icon = <TbIcon>hold</TbIcon>;
            break;

          case ConfController.ACTIVITY_PENDING:
            icon = <TbIcon>music</TbIcon>;
            break;

          case ConfController.ACTIVITY_5:
            icon = <TbIcon>volume-zero</TbIcon>;
            break;

          case ConfController.ACTIVITY_2:
            icon = <><TbIcon>volume-zero</TbIcon><TbIcon>volume-half</TbIcon></>;
            break;

          case ConfController.ACTIVITY_0:
            icon = <><TbIcon>volume-zero</TbIcon><TbIcon>volume-full</TbIcon></>;
            break;
          }

          return <div class="activity">{icon}</div>;
        },
      },
      host: {
        colKey: [ 'isActionAllowed', 'host' ],
        title: s.lblHost,
        sorting: {
          prop: 'host',
          reverse: true,
        },
        className: 'hover-cell shrink',
        create(cell) {
          return <CellToggleButton cell={cell} fieldName="host" />;
        },
      },
      muted: {
        colKey: [ 'isActionAllowed', 'muted' ],
        title: s.lblMuted,
        sorting: {
          prop: 'muted',
          reverse: true,
        },
        className: 'hover-cell shrink',
        create(cell) {
          return <CellToggleButton cell={cell} fieldName="muted" />;
        },
      },
      handRaised: {
        colKey: [ 'isHandRaisingActionAllowed', 'handRaisedIndexDisplay', 'isHandSelected' ],
        title: s.lblHands,
        sorting: {
          prop: 'handRaisedIndexDisplay',
          nullUndefInfinity: true,
        },
        className: 'hover-cell shrink',
        visibility: ({ setCallProps: { handRaisedIndexDisplay }}) => handRaisedIndexDisplay,
        create(cell) {
          if (cell.handRaisedIndexDisplay === null)
            return '';

          const { isHandSelected: selected } = cell;

          if (!cell.isHandRaisingActionAllowed) {
            return (
              <div classList={{'hand-raised-label': true, selected, }}>
                <span class="sr-only">{s.lblHandRaised}</span>
                <span class="order">
                  {cell.handRaisedIndexDisplay}
                </span>
              </div>
            );
          }

          return (
            <button type="button" class="btnHandRaised">
              <span class="sr-only">{s.lblHandRaised}</span>
              <span classList={{inner: true, selected, }}>
                <span class="order">
                  {cell.handRaisedIndexDisplay}
                </span>
              </span>
            </button>
          );
        },
      },
    };

    const hasCols = {
      starred: false,
      host: false,
      started: false,
    };

    const filterProps = [
      'callerIDDisplay', 'callerNameDisplay', 'userID', 'location',
    ];

    const columns = colConfig.map(col => {
      const { type } = col;

      if (type === 'internal') {
        const { id } = col;
        const def = colDefs[id];
        if (!def)
          throw new TypeError(`unknown colId '${id}'`);

        if (id in hasCols) hasCols[id] = true;

        return {
          id,
          ...def,
        };
      }

      if (type === 'custom') {
        const { prop, title, sum } = col;

        if (!filterProps.find(cur => cur === prop))
          filterProps.push(prop);

        return {
          colKey: prop,
          title,
          sorting: {
            prop,
          },
          className: 'shrink',
          visibility: ({ setCallProps: { [prop]: propVal }}) => propVal,
          ...(sum && {
            titleRender: ({ totals: { [prop]: totalVal }}) => `${title} (${totalVal})`,
          }),
        };
      }

      throw new TypeError(`unknown type '${type}'`);
    });

    const sortProps = Object.keys(hasCols).map(colId => {
      if (hasCols[colId])
        return {
          colId,
        };

      return {
        opts: colDefs[colId].sorting,
      };
    });

    super({
      ref,
      onCellButtonClick,
      columns,
      className: 'nowrap striped dataTable',
      itemKey: 'participantID',
      tfoot: true,
      onHeaderClick: e => {
        this._sorter.onHeaderClick(e);
        onUpdate();
      },
    });

    this._filter = null;
    this._filterProps = filterProps;

    this._sorter = new Sorter({
      table: this,
      sortMultiple: true,
      sortProps,
    });

    this._ctrl = ctrl;
    this._onUpdate = onUpdate;

    this._fillerRowTotal = fillerRowTotal;
    this._fillerRowCurrent = 0;
  }

  render(extra) {
    const sortProps = this._sorter.getSortProps();
    const data = this._ctrl.getCalls(
      item => this._filterItem(item),
      (a, b) => Sorter.compareItems(sortProps, a, b)
    );

    this.renderSort(sortProps);
    super.render(data, extra);

    let fillerRowsNeeded;

    if (data.length < this._fillerRowTotal) {
      fillerRowsNeeded = this._fillerRowTotal - data.length;
    } else {
      fillerRowsNeeded = 0;
    }

    toggleClass(this._tfoot, 'striped-inverse', data.length % 2 !== 0);

    if (this._fillerRowCurrent < fillerRowsNeeded) {
      const cols = this._columns.length;
      const tr = <tr />;
      for (let i = 0; i < cols; i++) tr.appendChild(<td/>);

      while (this._fillerRowCurrent < fillerRowsNeeded) {
        this._tfoot.appendChild(tr.cloneNode(true));
        this._fillerRowCurrent++;
      }
    } else if (this._fillerRowCurrent > fillerRowsNeeded) {
      while (this._fillerRowCurrent > fillerRowsNeeded) {
        this._tfoot.removeChild(this._tfoot.lastChild);
        this._fillerRowCurrent--;
      }
    }
  }

  setDisabled(disabled) {
    toggleClass(this.root, 'dimmer', disabled);
    this.root.querySelectorAll('th.sorting')
      .forEach(th => th.setAttribute('tabindex', disabled ? -1: 0));
  }

  setFilter(filter) {
    let newFilter = filter
      .trim()
      .toLowerCase();
    if (newFilter === '') newFilter = null;
    this._filter = newFilter;
  }

  changeFilter(filter) {
    const oldFilter = this._filter;
    this.setFilter(filter);
    if (this._filter !== oldFilter)
      this._onUpdate();
  }

  _filterItem(item) {
    if (this._filter === null) return true;

    for (let i = 0; i < this._filterProps.length; i++) {
      const prop = this._filterProps[i];
      if (item[prop] && item[prop].toLowerCase().includes(this._filter))
        return true;
    }

    return false;
  }
}
