import DOMEventEmitter from './DOMEventEmitter';

import { clamp } from 'MathUtil';

export default class RangeInput extends DOMEventEmitter {
  static isClassComponent = true;

  constructor({ min = 0, max = 1, step = 'any', defaultValue = 0, thumbWidth = 10, ref = null } = {}) {
    const root = document.createElement('input');

    super(root);

    if (ref) {
      ref(this);
    }

    this.root = root;
    this._min = min;
    this._max = max;
    this._step = step;
    this._defaultValue = defaultValue;
    this._thumbWidth = thumbWidth;

    root.type = 'range';
    root.min = min;
    root.max = max;
    root.step = step;
    root.value = defaultValue;

    this._updateTrackBackground();

    this.on('input change', e => {
      this._updateTrackBackground();
    });

    if (this._isTouchSupported) {
      this.on('touchend', e => this._handleTouch(e));
    }
  }

  get value() {
    return Number(this.root.value);
  }

  set value(value) {
    if (value < this._min || value > this._max) {
      value = this._defaultValue;
    }

    this.root.value = value;

    this._updateTrackBackground();
  }

  _handleTouch(e) {
    if (!this._isTouchSupported) {
      return;
    }

    const touch = e.changedTouches[0];
    const rect = this.root.getBoundingClientRect();

    const thumbWidth = this._thumbWidth;
    const effectiveWidth = rect.width - thumbWidth;
    const pos = touch.clientX - rect.left - thumbWidth / 2;
    const pct = clamp(pos / effectiveWidth, 0, 1);
    const value = this._getSteppedValue(pct);

    this.root.value = value;

    this.root.dispatchEvent(new Event('input'));
    this.root.dispatchEvent(new Event('change'));
  }

  get _isTouchSupported() {
    return 'ontouchstart' in document.documentElement;
  }

  _getSteppedValue(pct) {
    let value = pct * (this._max - this._min);
    if (this._step !== 'any') {
      value = Math.round(value / this._step) * this._step;
    }
    return this._min + value;
  }

  _updateTrackBackground(val) {
    this.root.style.setProperty('--value', `${((this.value - this._min) / (this._max - this._min)) * 100}%`);
  }
}
